import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import { ReactComponent as BurgerIcon } from 'src/assets/images/burger.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/cross.svg';
import { ROUTES } from 'src/constants/routes';

import styles from './HeaderMenu.module.scss';

export interface HeaderMenuProps {
  className?: string;
}

export const HeaderMenu = ({ className }: HeaderMenuProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const onControlClick = () => {
    setIsOpened((value) => !value);
  };

  const onClose = () => {
    setIsOpened(false);
  };

  return (
    <div className={cn(styles.root, className)}>
      <span className={styles.menuControl} onClick={onControlClick}>
        <BurgerIcon className={styles.menuControlIcon} />
      </span>

      <Drawer open={isOpened} onClose={onClose} PaperProps={{ className: styles.menuContentWrapper }}>
        <div className={styles.menuContent}>
          <div className={styles.menuCloseWrapper}>
            {/* TODO change to button */}
            <span className={styles.menuClose} onClick={onClose}>
              <CrossIcon className={styles.menuCloseIcon} />
            </span>
          </div>
          <nav>
            <ul className={styles.menuNavList}>
              <li className={styles.menuNavListItem} onClick={onClose}>
                <NavLink className={styles.menuNavLink} to={ROUTES.TERMS}>Terms of use</NavLink>
              </li>
              <li className={styles.menuNavListItem} onClick={onClose}>
                <NavLink className={styles.menuNavLink} to={ROUTES.PRIVACY_POLICY}>Privacy policy</NavLink>
              </li>
              <li className={styles.menuNavListItem} onClick={onClose}>
                <NavLink className={styles.menuNavLink} to={ROUTES.SUBSCRIPTION_TERMS}>Subscription terms</NavLink>
              </li>
              <li className={styles.menuNavListItem} onClick={onClose}>
                <NavLink className={styles.menuNavLink} to={ROUTES.MONEY_BACK_POLICY}>Money back policy</NavLink>
              </li>
              <li className={styles.menuNavListItem} onClick={onClose}>
                <NavLink className={styles.menuNavLink} to={ROUTES.CONTACT_US}>Contact us</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMenu;
