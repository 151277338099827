import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import usePrepareLink from 'src/hooks/usePrepareLink';
import InlineLink from 'src/components/common/InlineLink';
import Checkbox from 'src/components/common/Checkbox/Checkbox';
import Button from 'src/components/common/Button/Button';
import PackageDescription from 'src/components/PostQuiz/PackageDescription/PackageDescription';
import { ROUTES } from 'src/constants/routes';
import styles from './Package.module.scss';

const PrivacyPolicyConsentLabel = () => (
  <span>
    I agree to Lunar
    {' '}
    <InlineLink className={styles.inline_link} href={ROUTES.TERMS}>
      Terms of use
    </InlineLink>
    {' '}
    and
    {' '}
    <InlineLink className={styles.inline_link} href={ROUTES.PRIVACY_POLICY}>
      Privacy policy
    </InlineLink>
  </span>
);

const Package = () => {
  const history = useHistory();
  const [termsAgreed, setTermsAgreed] = useState(false);

  const purchase = usePrepareLink({
    query: {
      popup: 'purchase',
    },
  });

  const onGetMyReport = () => {
    if (termsAgreed) {
      history.push(purchase);
    }
  };

  return (
    <Element name="package" className={styles.container}>
      <p className={styles.price}>
        <span>ASTROLOGY APP SUBSCRIPTION:</span>
        <span>$19/2 weeks</span>
      </p>
      <p className={styles.price}>
        <span>PDF COMPATIBILITY REPORT:</span>
        <span>
          <span className={styles.price_discount}>$20</span>
          <span>$0</span>
        </span>
      </p>
      <p className={styles.price}>
        <span>TOTAL TODAY:</span>
        <span>$19</span>
      </p>
      <Button
        text="Get my report"
        className={styles.button}
        wrapperClassName={styles.button_wrapper}
        onClick={onGetMyReport}
      />
      <div className={styles.consents_container}>
        <div className={styles.consents_content}>
          <div className={styles.checkbox}>
            <Checkbox
              main
              label={<PrivacyPolicyConsentLabel />}
              checked={termsAgreed}
              name="privacy"
              onChange={() => setTermsAgreed((prev) => !prev)}
            />
          </div>
        </div>
        <p className={styles.description}>
          By continuing you agree that if you don’t cancel the subscription at
          least 24 hours prior to the end of the subscription period, you will
          automatically be charged $19 every 2 weeks until you cancel it by
          contacting the support team at
          {' '}
          <a href="mailto:support@stars-reader.com">support@stars-reader.com</a>
          . Learn more about cancellation and refund policy in
          {' '}
          <Link className={styles.link} to={ROUTES.SUBSCRIPTION_TERMS}>
            Subscription Terms
          </Link>
          . The charge will appear on your credit card statement as
          {' '}
          <a className={styles.link} href="http://strsrdrpay.com/">
            strsrdrpay.com
          </a>
        </p>
      </div>
      <PackageDescription />
    </Element>
  );
};

export default Package;
