/* eslint-disable quotes,react/no-unescaped-entities */
import React from "react";
import styles from "./MoneyBackPolicy.module.css";

const MoneyBackPolicy = () => (
  <div className={styles.container}>
    <h1>MONEY-BACK POLICY</h1>
    <h2>1. MONEY-BACK GUARANTEE RULES</h2>
    <p>
      In addition to refund rights available under applicable laws, if you made
      a purchase directly on our website and the money-back option was presented
      to you during the checkout, you are eligible to receive a refund provided
      that you contact us via
      {" "}
      <a href="https://osupporto-help.zendesk.com/hc/en-us">Support center</a>
      {" "}
      or email within 30 days after your initial purchase and before the end of
      your subscription period and provide us with the evidence that you have
      followed the plan for at least 7 consecutive days within the first 30 days
      after the purchase.
    </p>
    <p>
      We will review your application and notify you (by email) whether your
      application is approved.
    </p>
    <h2>IMPORTANT STATEMENT</h2>
    <p>
      Please note that only fulfilment of
      {' '}
      <b>the above requirement</b>
      {' '}
      allows
      you to receive a full Voluntary Refund under “Money-back guarantee”. For
      the sake of clarity, this “Money-back guarantee” does not apply to the
      following cases:
    </p>
    <p>
      1.1. Personal reasons (you don’t like the product, it did not meet your
      expectations etc.);
      <br />
      1.2. Financial reasons (you did not expect that you will be charged, that
      the trial will be converted into subscription, that the subscription will
      automatically renew, or that the services are paid etc.).
    </p>
    <br />
    <h2>2. GENERAL REFUND RULES</h2>
    <p>
      Generally, if you do not meet the conditions set out above, the fees you
      have paid are non-refundable and/or non-exchangeable, unless otherwise is
      stated herein or is required by applicable law.
    </p>
    <p>
      <b>Note for residents of certain US states.</b>
      {' '}
      If you reside in
      California or Connecticut and cancel the purchase at any time prior to
      midnight of the third business day after the date of such purchase, we
      will return the payment you have made.
    </p>
    <p>
      <b>Note for the EU residents:</b>
      {' '}
      If you are an EU resident, you have the
      right to withdraw from the agreement for the purchase of digital content
      without charge and without giving any reason within fourteen (14) days
      from the date of such agreement conclusion. The withdrawal right does not
      apply if the performance of the agreement has begun with your prior
      express consent and your acknowledgment that you thereby lose your right
      of withdrawal.
      <b>
        You hereby expressly consent to the immediate performance of the
        agreement and acknowledge that you will lose your right of withdrawal
        from the agreement once our servers validate your purchase and the
        applicable purchase is successfully delivered to you.
      </b>
      {" "}
      Therefore, you will not be eligible for a refund, unless the digital
      content is defective.
    </p>
    <p>Last updated: 08 November 2022</p>
  </div>
);

export default MoneyBackPolicy;
