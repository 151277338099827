import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import usePrepareLink from './usePrepareLink';
import useCardPayment from './useCardPayment';
import usePaypalPayment from './usePaypalPayment';
import useServices from './useServices';

export default function usePurchase() {
  const history = useHistory();
  const { purchaseService } = useServices();

  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cardPaymentUrl, setCardPaymentUrl] = useState<unknown>();
  const [paypalPaymentUrl, setPaypalPaymentUrl] = useState('');

  const purchaseFailed = usePrepareLink({
    query: {
      popup: 'purchaseFailed',
    },
  });

  useEffect(() => {
    purchaseService.createOrder().then((paymentData) => {
      setCardPaymentUrl(paymentData.cardPaymentUrl);
      setPaypalPaymentUrl(paymentData.paypalPaymentUrl);
    });
  }, [purchaseService]);

  const onOrderStartProcessing = useCallback(() => {
    setIsOrderProcessing(true);
  }, []);

  const onOrderApproved = useCallback(() => {
    setIsOrderProcessing(false);
    history.replace(ROUTES.SUCCESS);
  }, []);

  const onOrderDeclined = useCallback(() => {
    setIsOrderProcessing(false);
    history.replace(purchaseFailed);
  }, []);

  useEffect(() => {
    if (cardPaymentUrl && paypalPaymentUrl) {
      setIsLoaded(true);
    }
  }, [cardPaymentUrl, paypalPaymentUrl]);

  useCardPayment({
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  });

  const { paypalRef, paypalButtonRef } = usePaypalPayment({
    paypalPaymentUrl,
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  });

  return {
    isLoaded,
    isOrderProcessing,
    cardPaymentUrl,
    paypalRef,
    paypalButtonRef,
  };
}
