import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useApp } from 'src/hooks/useApp';
import { ServicesContext } from 'src/context';
import styles from 'src/components/App/App.module.scss';
import Flow from 'src/pages/Flow/Flow';
import Gender from 'src/pages/Gender/Gender';
import Overthink from 'src/pages/Overthink/Overthink';
import Details from 'src/pages/Details/Details';
import Trust from 'src/pages/Trust/Trust';
import Processing from 'src/pages/Processing/Processing';
import PostQuiz from 'src/pages/PostQuiz/PostQuiz';
import Success from 'src/pages/Success/Success';
import Terms from 'src/pages/Terms/Terms';
import ContactUs from 'src/pages/ContactUs/ContactUs';
import FAQ from 'src/pages/FAQ/FAQ';
import { ROUTES } from 'src/constants/routes';
import SubscriptionTerms from 'src/pages/SubscriptionTerms/SubscriptionTerms';
import MoneyBackPolicy from 'src/pages/MoneyBackPolicy/MoneyBackPolicy';
import Header from '../Header/Header';
import PopUpContainer from '../PopUpContainer/PopUpContainer';
import CookiesAgreement from '../CookiesAgreement';

const App = () => {
  const { isAppLoaded, store, services } = useApp();

  if (!isAppLoaded || !store || !services) {
    return <>Loading</>;
  }

  return (
    <Provider store={store}>
      <ServicesContext.Provider value={services}>
        <div className={styles.container}>
          <div className={styles.app}>
            <Header />
            <PopUpContainer />
            <div className={styles.content}>
              <Switch>
                <Route path={ROUTES.FLOW} exact component={Flow} />
                <Route path={ROUTES.GENDER} exact component={Gender} />
                <Route path={ROUTES.OVERTHINK} exact component={Overthink} />
                <Route path={ROUTES.DETAILS} exact component={Details} />
                <Route path={ROUTES.TRUST} exact component={Trust} />
                <Route path={ROUTES.PROCESSING} exact component={Processing} />
                <Route path={ROUTES.POST_QUIZ} exact component={PostQuiz} />
                <Route path={ROUTES.SUCCESS} exact component={Success} />
                <Route path={ROUTES.TERMS} exact component={Terms} />
                <Route
                  path={ROUTES.MONEY_BACK_POLICY}
                  exact
                  component={MoneyBackPolicy}
                />
                <Route
                  path={ROUTES.SUBSCRIPTION_TERMS}
                  exact
                  component={SubscriptionTerms}
                />
                <Route path={ROUTES.FAQ} exact component={FAQ} />
                <Route path={ROUTES.CONTACT_US} exact component={ContactUs} />
              </Switch>
            </div>
            <CookiesAgreement />
          </div>
        </div>
      </ServicesContext.Provider>
    </Provider>
  );
};

export default App;
