import React from 'react';
import cn from 'classnames';
import styles from './Copyright.module.scss';

const Copyright = ({ className = '' }) => (
  <div className={cn(styles.container, className)}>
    <p className={styles.copyright}>
      Office 3500 South Dupont Highway, Dover, DE 19901, County of Kent
    </p>
  </div>
);

export default Copyright;
