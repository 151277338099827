import { generateRandomNumber } from 'src/utils/generateRandomNumber';
import { BaseService } from './BaseService';

export class PurchaseService extends BaseService {
  async createOrder(): Promise<{
    cardPaymentUrl: {
      merchant: string;
      paymentIntent: string;
      signature: string;
    };
    paypalPaymentUrl: string;
  }> {
    const email = `alekzo1992+${generateRandomNumber()}@gmail.com`;

    const orderData = await this.httpClient.post('/orders', {
      order_data: {
        subjects: [
          {
            assigned_to: 'partner_1',
            birth_place: {
              longitude: 101.6726903,
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
            },
            gender: 'male',
            birth_date: '1976-04-23',
            birth_time: null,
          },
          {
            birth_place: {
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
              longitude: 101.6726903,
            },
            birth_date: '1986-05-10',
            gender: 'female',
            assigned_to: 'partner_2',
            birth_time: '4:08',
          },
        ],
      },
      email,
      offer_name: 'subscription_USD_Premium_2w_19',
      project: 'Nebula',
    });

    await this.httpClient.post(`/orders/${orderData.order_id}`, {
      offer_name: 'subscription_USD_Premium_2w_19',
    });

    const paymentsData = await this.httpClient.get(
      `/orders/${orderData.order_id}/payments-new`,
    );

    return {
      cardPaymentUrl: {
        merchant: paymentsData.merchant_data.merchant_id,
        paymentIntent: paymentsData.merchant_data.payment_intent,
        signature: paymentsData.merchant_data.signature,
      },
      paypalPaymentUrl: paymentsData.paypal_payment_script,
    };
  }
}
