/* eslint-disable quotes,react/no-unescaped-entities */
import React from "react";
import styles from "./SubscriptionTerms.module.css";

const SubscriptionTerms = () => (
  <div className={styles.container}>
    <h1>SUBSCRIPTION TERMS</h1>
    <h2>1. Trial</h2>
    <p>
      We may offer a trial subscription for service. Unless you request
      cancellation at least 24 hours before the end of the trial, you will be
      automatically charged a price indicated on the payment screen for a chosen
      subscription period.
    </p>
    <h2>2. Subscription</h2>
    <p>
      The subscription renews automatically at the end of each period (each
      week, month, 6 months, year, or otherwise, depending on the option
      selected by you at the time of purchase) until you cancel.
    </p>
    <h2>3. Payment method</h2>
    <p>
      Payment will be charged to the payment method you submitted at the time of
      purchase at confirmation of purchase. You authorize us to charge the
      applicable fees to the payment method that you submit.
    </p>
    <h2>4. Cancellation</h2>
    <p>
      Cancelling your subscription means that the automatic renewal will be
      disabled, but you will still have access to all your subscription features
      for the remaining time of then-current period. Note that deleting the app
      does not cancel your subscriptions.
    </p>
    <ul className={styles.list}>
      <li>
        <b>If you purchased a subscription or enabled trial on App Store:</b>
        <p>
          You can cancel a trial or a subscription anytime by turning-off
          autorenewal through your Apple ID account settings. To avoid being
          charged, cancel the subscription in your Apple ID account settings at
          least 24 hours before the end of the trial or then-current
          subscription period. You alone can manage your subscriptions. Learn
          more about managing subscriptions (and how to cancel them) on Apple
          support page.
        </p>
      </li>
      <li>
        <b>If you purchased a subscription or enabled trial on Google Play:</b>
        <p>
          You can cancel a trial or a subscription anytime by turning off
          auto-renewal through your Google Play account settings. To avoid being
          charged, cancel the subscription in your account settings at least 24
          hours before the end of the trial or then-current subscription period.
          You alone can manage your subscriptions. Learn more about managing
          subscriptions (and how to cancel them) on Google’s support page.
        </p>
      </li>
      <li>
        <b>If you purchased a subscription or enabled trial on our website:</b>
        <p>
          You can cancel a trial or a subscription by contacting support team at
          {" "}
          <a href="https://osupporto-help.zendesk.com/hc/en-us">
            Support center
          </a>
          {" "}
          or by using “Cancel Subscription” mechanism on the website or inside
          the app at least 24 hours before the end of the trial or then-current
          subscription period.
        </p>
      </li>
    </ul>
    <h2>5. Changes</h2>
    <p>
      To the maximum extent permitted by applicable laws, we may change
      subscription fees at any time. We will give you reasonable notice of any
      such pricing changes by posting the new prices on the app and/or by
      sending you an email notification, or in other prominent way. If you do
      not wish to pay the new fees, you can cancel the applicable subscription
      prior to the change going into effect.
    </p>
    <h2>6. Refunds</h2>
    <ul className={styles.list}>
      <li>
        <b>If you purchased a subscription or enabled trial on App Store:</b>
        <p>
          If you are eligible for a refund, you’ll have to request it directly
          from Apple. To request a refund, follow these instructions from the
          Apple support page.
        </p>
      </li>
      <li>
        <b>If you purchased a subscription or enabled trial on Google Play:</b>
        <p>
          If you are eligible for a refund, you`ll have to request it directly
          from Google. To request a refund, follow these instructions from the
          Google’s support page.
        </p>
      </li>
      <li>
        <b>If you purchased a subscription or enabled trial on our website:</b>
        <p>
          Please refer to our Money-back guarantee policy/Terms and Conditions
          of Use.
        </p>
      </li>
    </ul>
    <h2>7. Contacts</h2>
    <p>
      If you have any questions in relation to the subscription terms, please
      contact us at
      {" "}
      <a href="https://osupporto-help.zendesk.com/hc/en-us">Support center.</a>
    </p>
    <p>
      Please take a screenshot of this information for your reference. This may
      help you to control your subscriptions.
    </p>
    <p>Last updated: 08 November 2022</p>
  </div>
);

export default SubscriptionTerms;
